import React, { Component, Fragment } from 'react';
import { WrapperSeamless, PageTitle } from '../../layout-components';
import {
  serverLink,
  hospitalCode,
  patientSerial, patient_login_token
} from '../../resources/connection';
import axios from 'axios';
import defaultImage from '../../assets/images/user.png';
import { Button, Card, CardContent, Grid, LinearProgress } from '@material-ui/core';
import { Link } from "react-router-dom"

class AppointmentDetail extends Component {
  constructor() {
    super();
    let page_url = window.location.pathname;
    let page_id = page_url.split('/');
    let id = page_id[2];
    this.state = {
      appointmentID: id,
      isLoading: true,
      patientData: '',
      dependantList: [],
      appointmentData: '',
      onlineConsultation: ''
    };
  }

  componentDidMount() {
    this.getAppointmentRecord();
  }

  currencyConverter = amount => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NGN'
    });
    return formatter.format(amount);
  };

  formatDate = date => {
    if (date !== null) {
      const user_date = new Date(date);
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];

      return `${user_date.getDate()}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()}`;
    } else {
      return '--';
    }
  };

  getAppointmentRecord = async () => {
    await axios
      .get(`${serverLink}appointment/${this.state.appointmentID}`, patient_login_token)
      .then(result => {
        if (typeof result.data.appointmentTime !== "undefined") {
          this.setState({
            isLoading: false,
            appointmentData: result.data
          });
        } else {
          window.location.href = '/Appointments'
        }

      })
      .catch(error => {
        console.log('Dashboard data error', error);
      });

    await axios
      .get(`${serverLink}appointment/consultation/online/link/by_appointment/${this.state.appointmentID}`, patient_login_token)
      .then(result => {
        console.log(result.data)
        this.setState({
          onlineConsultation: result.data
        });

      })
      .catch(error => {
        console.log('Dashboard data error', error);
      });
  };

  render() {
    return (
      <Fragment>
        <PageTitle titleHeading="Appointment Page" titleDescription="" />
        {
          this.state.isLoading === true ?
            <LinearProgress value={25} className="mb-3" /> :
            <>
              <WrapperSeamless sectionHeading="Cards 4">
                <Fragment>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Card className="mb-4">
                        <CardContent className="p-3">
                          <h5 className="card-title font-weight-bold font-size-lg">
                            Appointment Details
                          </h5>
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <tbody>
                              <tr>
                                <th>Patient Name</th>
                                <td>{this.state.appointmentData.patientName}</td>
                              </tr>
                              <tr>
                                <th>Patient ID</th>
                                <td>{this.state.appointmentData.patientSerial}</td>
                              </tr>
                              <tr>
                                <th>Doctor Name</th>
                                <td>{this.state.appointmentData.doctorName}</td>
                              </tr>
                              <tr>
                                <th>Department</th>
                                <td>{this.state.appointmentData.appointmentDepartment}</td>
                              </tr>
                              <tr>
                                <th>Appointment Date</th>
                                <td>{this.formatDate(this.state.appointmentData.appointmentDate)}</td>
                              </tr>
                              <tr>
                                <th>Appointment Time</th>
                                <td>{this.state.appointmentData.appointmentTime}</td>
                              </tr>
                              <tr>
                                <th>Submitted On</th>
                                <td>{this.formatDate(this.state.appointmentData.submittedOn)}</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>

                        </CardContent>
                      </Card>

                      {
                        this.state.onlineConsultation != null && this.state.onlineConsultation.consultationStatus === 'incomplete' &&
                        <Card className="mb-4">
                          <CardContent className="p-3">
                            <h5 className="card-title font-weight-bold font-size-lg">
                              Online Consultation Details
                            </h5>

                            <div className="table-responsive">
                              <table className="table table-striped">
                                <tbody>
                                <tr>
                                  <th>Consultation Date</th>
                                  <td>{this.formatDate(this.state.onlineConsultation.consultationDate)}</td>
                                </tr>
                                <tr>
                                  <th>Consultation Time</th>
                                  <td>{this.state.onlineConsultation.consultationTime}</td>
                                </tr>
                                <tr>
                                  <th>Doctor Name</th>
                                  <td>{this.state.onlineConsultation.doctorName}</td>
                                </tr>
                                <tr>
                                  <th>Meeting Link</th>
                                  <td><a href={this.state.onlineConsultation.consultationLink} target="_blank">visit</a></td>
                                </tr>
                                </tbody>
                              </table>
                            </div>

                          </CardContent>
                        </Card>
                      }

                    </Grid>
                  </Grid>
                </Fragment>
              </WrapperSeamless>
            </>
        }
      </Fragment>
    );
  }
}
export default AppointmentDetail;
