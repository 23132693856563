import React, { Component, Fragment } from 'react';

import { PageTitle } from '../../layout-components';

import DashboardSection1 from '../../components/DashboardComponent/DashboardSection1';
import DashboardDefaultSection2 from '../../components/DashboardComponent/DashboardSection2';
import {
  serverLink,
  hospitalCode,
  patientSerial, patient_login_token
} from '../../resources/connection';
import axios from 'axios';
import { LinearProgress } from '@material-ui/core';

class Appointments extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      patientSerial: patientSerial,
      hospitalCode: hospitalCode,
      appointmentCount: 0,
      currentWallet: this.currencyConverter(0),
      transactionCount: 0,
      appointmentList: []
    };
  }

  componentDidMount() {
    this.getPatientCount();
  }

  currencyConverter = amount => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NGN'
    });
    return formatter.format(amount);
  };

  formatDate = date => {
    if (date !== null) {
      const user_date = new Date(date);
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];

      return `${user_date.getDate()}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()}`;
    } else {
      return '--';
    }
  };

  getPatientCount = () => {
    axios
      .get(
        `${serverLink}patient/dashboard/count/${hospitalCode}/${this.state.patientSerial}`, patient_login_token
      )
      .then(result => {
        this.setState({
          isLoading: false,
          currentWallet: this.currencyConverter(result.data.currentWallet),
          appointmentCount: result.data.appointments,
          appointmentList: result.data.appointmentList
        });
      })
      .catch(error => {
        console.log('Dashboard data error', error);
      });
  };
  render() {
    return (
      <Fragment>
        <PageTitle titleHeading="Appointments" titleDescription="" />
        {
          this.state.isLoading === true ?
            <LinearProgress value={25} className="mb-3" /> :
            <>
              <DashboardDefaultSection2
                appointmentList={this.state.appointmentList}
                formatDate={this.formatDate}
                limit={50}
              />
            </>
        }
      </Fragment>
    );
  }
}
export default Appointments;
