import React, { Component, Fragment } from 'react';
import './index.css';
import { WrapperSeamless, PageTitle } from '../../layout-components';
import {
  serverLink,
  hospitalCode,
  patientSerial, patientFullName, patient_login_token
} from '../../resources/connection';
import axios from 'axios';
import {
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  LinearProgress,
  MenuItem,
  TextField
} from '@material-ui/core';
import PackageJson from '../../../package.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiAlert from '@material-ui/lab/Alert';

class UpdatePassword extends Component {

  constructor() {
    super();
    this.state = {
      isLoading: false,
      formData: {
        patientSerial: patientSerial,
        hospitalCode: hospitalCode,
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
      },
      onSubmitting: false,
      onError: false,
      onSuccess: false
    };

  }

  handleChange = (event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.id]: event.target.value
      }
    })
  }

  changePassword (sendData) {
    this.setState({
      onSubmitting: true,
      onError: false,
      onSuccess: false
    })

    if (sendData.currentPassword === "") {
      this.setState({
        onSubmitting: false,
        onError: true,
        onSuccess: false,
        onMessage: "Please enter your current password"
      })
      return false;
    }

    if (sendData.newPassword === "") {
      this.setState({
        onSubmitting: false,
        onError: true,
        onSuccess: false,
        onMessage: "Please enter your new password"
      })
      return false;
    }

    if (sendData.confirmPassword === "") {
      this.setState({
        onSubmitting: false,
        onError: true,
        onSuccess: false,
        onMessage: "Please confirm your new password"
      })
      return false;
    }

    if (sendData.confirmPassword !== sendData.newPassword) {
      this.setState({
        onSubmitting: false,
        onError: true,
        onSuccess: false,
        onMessage: "Your new password and confirm password didn't match. Please try again!!!"
      })
      return false;
    }

    if (sendData.newPassword.length < 8) {
      this.setState({
        onSubmitting: false,
        onError: true,
        onSuccess: false,
        onMessage: "Your new password cannot be less than 8 characters"
      })
      return false;
    }

    axios.patch(`${serverLink}patient/update_password`, sendData, patient_login_token)
      .then(response => {

        if (response.data.message === 'error') {
          this.setState({
            onSubmitting: false,
            onError: true,
            onSuccess: false,
            onMessage: "Invalid data submitted. Please try again!"
          })
        }
        else if (response.data.message === 'not exist') {
          this.setState({
            onSubmitting: false,
            onError: true,
            onSuccess: false,
            onMessage: "Record not exist. Please try again!"
          })
        }
        else if (response.data.message === 'success') {
          this.setState({
            onSubmitting: false,
            onError: false,
            onSuccess: true,
            onMessage: ""
          })

          setTimeout(function() {
            window.location.href = '/Dashboard'
          },3000)
        }
        else if (response.data.message === 'invalid password') {
          this.setState({
            onSubmitting: false,
            onError: true,
            onSuccess: false,
            onMessage: "Invalid current password. Please check and try again!"
          })
        }
      })
      .catch(error => {
        this.setState({
          onSubmitting: false,
          onError: true,
          onSuccess: false,
          onMessage: "Network error. Update failed. Please try again!!!"
        })
      })

  }


  render() {
    return (
      <Fragment>
        <PageTitle titleHeading='Add Appointment' titleDescription='' />

        {
          this.state.isLoading === true ?
            <LinearProgress value={25} className='mb-3' /> :
            <>
              <WrapperSeamless sectionHeading='Update Password'>
                <Fragment>
                  <Grid container spacing={4}>

                    <Grid item xs={12} sm={12} md={12}>
                      <Card className='mb-4'>
                        <CardContent className='p-3'>
                          <h5 className='card-title font-weight-bold font-size-lg'>
                            Update Password
                          </h5>
                          <hr />

                          <div className='p-3'>


                            <TextField
                              fullWidth
                              type='password'
                              label='Your Current Password'
                              id='currentPassword'
                              name='currentPassword'
                              onChange={this.handleChange}
                              helperText='Enter your current password'
                              className='m-2'
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>Current Password</InputAdornment>
                                )
                              }}
                            />

                            <TextField
                              fullWidth
                              type='password'
                              label='New Password'
                              id='newPassword'
                              name='newPassword'
                              onChange={this.handleChange}
                              helperText='Enter your new password'
                              className='m-2'
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>New Password</InputAdornment>
                                )
                              }}
                            />

                            <TextField
                              fullWidth
                              type='password'
                              label='Confirm New Password'
                              id='confirmPassword'
                              name='confirmPassword'
                              onChange={this.handleChange}
                              helperText='Confirm your password'
                              className='m-2'
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>Confirm New Password</InputAdornment>
                                )
                              }}
                            />

                            <Button
                              onClick={() => this.changePassword(this.state.formData)}
                              size="small"
                              variant="contained"
                              className="mt-3 mb-2 button"
                              color="primary">
                              <FontAwesomeIcon icon={['fas', 'lock']} />
                               Submit
                            </Button>

                            {
                              this.state.onSuccess === true ?
                                <MuiAlert className='mb-4' severity='success'>
                                  <div className='d-flex align-items-center align-content-center'>
                              <span>
                                <strong className='d-block'>Password changed successfully!</strong> {this.state.onMessage}
                              </span>
                                  </div>
                                </MuiAlert>
                                : ''
                            }

                            {
                              this.state.onError === true ?
                                <MuiAlert className='mb-4' severity='error'>
                                  <div className='d-flex align-items-center align-content-center'>
                              <span>
                                <strong className='d-block'>Error!</strong> {this.state.onMessage}
                              </span>
                                  </div>
                                </MuiAlert>
                                : ''
                            }

                            {
                              this.state.onSubmitting === true ?
                                <MuiAlert className='mb-4' severity='info'>
                                  <div className='d-flex align-items-center align-content-center'>
                                    <span>
                                      <strong className='d-block'>Processing! Please wait</strong> {this.state.onMessage}
                                    </span>
                                  </div>
                                </MuiAlert> : ''
                            }
                          </div>


                        </CardContent>
                      </Card>
                    </Grid>

                  </Grid>
                </Fragment>
              </WrapperSeamless>
            </>
        }

      </Fragment>
    );
  }
}

export default UpdatePassword;
