import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import {
  Card,
  CardContent,
  Button,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class DashboardDefaultSection2 extends Component {
  render() {
    return (
      <Fragment>
        <Card className="card-box mb-4">
          <div className="card-header pr-2">
            <div className="card-header--title">
              Recent / Upcoming Appointments
            </div>
          </div>
          <CardContent className="p-3">
            <div className="table-responsive">
              <table className="table table-borderless table-hover text-nowrap mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="text-left">Department</th>
                    <th className="text-left">Consultation</th>
                    <th className="text-left">Doctor</th>
                    <th className="text-left">Date</th>
                    <th className="text-left">Time</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                {this.props.appointmentList.length === 0 ? (
                  <tbody>
                    <tr>
                      <td>No Appointment Found</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {this.props.appointmentList.map((appointment, index) => {
                      if (index < this.props.limit) {
                        return (
                          <tr key={appointment._id}>
                            <td className="">{index + 1}</td>
                            <td className="">
                              {appointment.appointmentDepartment}
                            </td>
                            <td className="">
                              {appointment.consultationType}
                            </td>
                            <td className="">
                              {appointment.doctorName}
                            </td>
                            <td className="">
                              {this.props.formatDate(
                                appointment.appointmentDate
                              )}
                            </td>
                            <td className="">
                              {appointment.appointmentTime}
                            </td>
                            <td className="text-center">
                              <div className="badge badge-warning px-4">
                                {appointment.appointmentStatus}
                              </div>
                            </td>
                            <td className="text-center">
                              <Link to={`/Appointment/${appointment._id}`}>
                                <Tooltip arrow title="View Details">
                                  <IconButton
                                    size="small"
                                    variant="outlined"
                                    color="primary">
                                    <FontAwesomeIcon
                                      icon={['fas', 'arrow-right']}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </CardContent>
          <div className="card-footer d-flex justify-content-between">
            <div>
              <Link to="/AddAppointment">
                <Button
                  size="small"
                  variant="contained"
                  className="mr-3"
                  color="primary">
                  <FontAwesomeIcon icon={['fas', 'plus']} />
                  Add New Appointment
                </Button>
              </Link>
            </div>
          </div>
        </Card>
      </Fragment>
    );
  }
}
export default DashboardDefaultSection2;
