import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Box } from '@material-ui/core';

import PackageJson from '../../../package.json';
import projectLogo from '../../assets/images/logo.png';
const HeaderLogo = props => {
  return (
    <Fragment>
      <div className={clsx('app-header-logo', {})}>
        <Box className="header-logo-wrapper" title={PackageJson.title}>
          <Link to="/Dashboard" className="header-logo-wrapper-link">
            <img
              className="app-header-logo-img"
              alt={PackageJson.title}
              src={projectLogo}
            />
          </Link>
          <Box className="header-logo-text">{PackageJson.title}</Box>
        </Box>
      </div>
    </Fragment>
  );
};

export default HeaderLogo;
