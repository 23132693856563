import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box } from '@material-ui/core';
import {
  patientFullName,
  patientPassport,
  patientSerial
} from '../../resources/connection';
export default function HeaderUserbox() {
  return (
    <Fragment>
      <Box>
        {patientPassport !== '' ? (
          <Avatar sizes="44" alt={patientFullName} src={patientPassport} />
        ) : (
          <FontAwesomeIcon icon={['fas', 'user']} className="opacity-5" />
        )}
      </Box>
      <div className="d-none d-xl-block pl-3">
        <div className="font-weight-bold pt-2 line-height-1">
          {patientFullName}
        </div>
        <span className="text-white-50">{patientSerial}</span>
      </div>
    </Fragment>
  );
}
