import React, { Component, Fragment } from 'react';

import { PageTitle } from '../../layout-components';
import DashboardDefaultSection2 from '../../components/DashboardComponent/DashboardSection2';
import {
  serverLink,
  hospitalCode,
  patientSerial
} from '../../resources/connection';
import axios from 'axios';
import { LinearProgress } from '@material-ui/core';

class Logout extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    this.logOut();
  }


  logOut = () => {
    localStorage.removeItem('patientFullName')
    localStorage.removeItem('patientSerial')
    localStorage.removeItem('patientPassport')
    localStorage.removeItem('hospitalCode')
    localStorage.removeItem('patientToken')
    window.location.href = 'https://bazeuniversityhospital.com'
  };

  render() {
    return (
      <Fragment>
        <PageTitle titleHeading="Logout" titleDescription="" />
        {
          this.state.isLoading === true ?
            <LinearProgress value={25} className="mb-3" /> : ''
        }
      </Fragment>
    );
  }
}
export default Logout;
