import CryptoJS from 'crypto-js';
import axios from 'axios';

const status = 'Live';
export const serverLink =
  status === 'Dev'
    ? 'http://localhost:4480/'
    : 'https://bazeuniversityhospital-server.herokuapp.com/';

const hospital_code = CryptoJS.AES.decrypt(
  localStorage.getItem('hospitalCode'),
  'BazeUniversityHospitalPatientDashboard'
);

export const patient_login_token = {headers: {"buh-token" : localStorage.getItem('patientToken')}}

export const hospitalCode = hospital_code.toString(CryptoJS.enc.Utf8);

const patient_serial = CryptoJS.AES.decrypt(
  localStorage.getItem('patientSerial'),
  'BazeUniversityHospitalPatientDashboard'
);
export const patientSerial = patient_serial.toString(CryptoJS.enc.Utf8);

const patient_full_name = CryptoJS.AES.decrypt(
  localStorage.getItem('patientFullName'),
  'BazeUniversityHospitalPatientDashboard'
);
export const patientFullName = patient_full_name.toString(CryptoJS.enc.Utf8);

const patient_passport = CryptoJS.AES.decrypt(
  localStorage.getItem('patientPassport'),
  'BazeUniversityHospitalPatientDashboard'
);
let passport = patient_passport.toString(CryptoJS.enc.Utf8);

axios
  .get(`${serverLink}patient/${hospitalCode}/${patientSerial}`, patient_login_token)
  .then(result => {
    if (!result.data) {
      window.location.href = 'https://bazeuniversityhospital.com';
    } else {
      passport = result.data.passport;
    }
  })
  .catch(error => {
    console.log('Patient Verification Error', error);
  });

export const patientPassport = passport;
