import React, { Component } from 'react';

export default class WrapperSeamless extends Component {
  render() {
    return (
      <div className="example-card-seamless mb-4-spacing">
        <div>{this.props.children}</div>
      </div>
    );
  }
}
