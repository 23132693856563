import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './theme';

// Layout Blueprints

import { LeftSidebar } from './layout-blueprints';

// Pages
import FormsControls from './pages/FormsControls';
import AddAppointment from './pages/AddAppointment';
import Appointments from './pages/Appointments';
import AppointmentDetail from './pages/AppointmentDetail';
import UpdatePassword from './pages/UpdatePassword';
import Logout from './pages/Logout';

const Dashboard = lazy(() => import('./pages/DashboardPage'));
const Profile = lazy(() => import('./pages/Profile'));
const Payment = lazy(() => import('./pages/PaymentPage'));
const AddPayment = lazy(() => import('./pages/AddPayment'));

const Notifications = lazy(() => import('./pages/Notifications'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense
          fallback={
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
              <div className="w-50 mx-auto">
                Please wait while we load the content
              </div>
            </div>
          }>
          <Switch>
            <Redirect exact from="/" to="/Dashboard" />
            <Route
              path={[
                '/Dashboard',
                '/Profile',
                '/Payment',
                '/AddPayment',
                '/AddAppointment',
                '/Appointments',
                '/Appointment/:id',
                '/UpdatePassword',
                '/Modals',
                '/Notifications',
                '/Logout',
                '/FormsControls'
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/Dashboard" component={Dashboard} />
                    <Route path="/Profile" component={Profile} />
                    <Route path="/Payment" component={Payment} />
                    <Route path="/AddPayment" component={AddPayment} />
                    <Route path="/AddAppointment" component={AddAppointment} />
                    <Route path="/Appointments" component={Appointments} />
                    <Route path="/Appointment/:id" component={AppointmentDetail} />
                    <Route path="/Notifications" component={Notifications} />
                    <Route path="/FormsControls" component={FormsControls} />
                    <Route path="/Logout" component={Logout} />
                    <Route path="/UpdatePassword" component={UpdatePassword} />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
