import React, { Component, Fragment } from 'react';
import './index.css';
import { WrapperSeamless, PageTitle } from '../../layout-components';
import {
  serverLink,
  hospitalCode,
  patientSerial,
  patientFullName, patient_login_token
} from '../../resources/connection';
import axios from 'axios';
import {
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  LinearProgress,
  MenuItem,
  TextField
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiAlert from '@material-ui/lab/Alert';

class AddAppointment extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      patientRecord: '',
      walletAmount: this.currencyConverter(0),
      dependantList: [],
      departmentList: [],
      doctorList: [],
      formData: {
        patientSerial: patientSerial,
        patientName: patientFullName,
        appointmentDetail: '',
        appointmentDay: '',
        appointmentDate: '',
        appointmentTime: '',
        doctorID: '',
        doctorName: '',
        appointmentStatus: '',
        appointmentDepartment: '',
        appointmentFee: '',
        hospitalCode: hospitalCode,
        isNotifiedPatient: false,
        notifiedOn: '',
        submittedBy: patientSerial,
        consultationAmount: 0,
        serviceItemName: '',
        serviceID: ''
      },
      onShowAppointmentDate: true,
      doctorSlots: '',
      onSubmitting: false,
      onError: false,
      onSuccess: false,
      serviceList: [],
      serviceData: [],
      selectedItemID: '',
      selectedItemName: '',
      serviceDataSelect: []
    };
  }

  componentDidMount() {
    this.getPatientRecord();
    this.onGetDepartments();
    this.getServiceList();
    this.getServiceData();
  }

  onGetDepartments() {
    axios
      .get(`${serverLink}specialisation/all/${hospitalCode}`, patient_login_token)
      .then(specialisation => {
        if (specialisation.data.length < 1) {
          this.setState({
            departmentList: ''
          });
        } else {
          this.setState({
            departmentList: specialisation.data
          });
        }
      })
      .catch(err => {
        this.setState({
          departmentList: null
        });
      });
  }

  async getServiceList() {
    await axios
      .get(`${serverLink}service/all/${hospitalCode}`, patient_login_token)
      .then(result => {
        const data = result.data;
        const result_set = data.filter(item =>
          item.serviceName.includes('Consultation')
        );
        this.setState({
          serviceList: result_set
        });
      })
      .catch(error => {
        this.setState({
          messageType: 'error',
          errorMessage: 'Error connecting to server to fetch service list'
        });
      });
  }
  //============ GET PATIENT SERVICE LIST END ====================

  //============ GET PATIENT SERVICE DATA STARTS ====================
  async getServiceData() {
    await axios
      .get(`${serverLink}service/service_data_list`, patient_login_token)
      .then(result => {
        const data = result.data;
        if (data.length > 0)
          this.setState({
            serviceData: data
          });
      })
      .catch(error => {
        this.setState({
          messageType: 'error',
          errorMessage: 'Error connecting to server to fetch service list'
        });
      });
  }

  onServiceChange = e => {
    const service_type = e.target.value;
    const service_data = this.state.serviceData;
    if (service_data.length > 0) {
      let record = service_data.filter(item => item.serviceID === service_type);
      this.setState({
        formData: {
          ...this.state.formData,
          serviceType: service_type,
          appointmentFee: 0,
          consultationAmount: 0,
          serviceItemName: '',
          serviceID: ''
        }
      });
      if (record.length > 0) {
        this.setState({
          serviceDataSelect: record
        });
      }
    }
  };

  onServiceItemChange = e => {
    const formData = this.state.formData;
    const data = JSON.parse(e.target.value);
    const service_item_id = data._id;
    const service_amount = data.serviceAmount;
    const service_item_name = data.serviceItemName;

    if (service_item_id === '') return false;

    this.setState({
      formData: {
        ...formData,
        appointmentFee: service_amount,
        consultationAmount: service_amount,
        serviceItemName: service_item_name,
        serviceID: service_item_id
      }
    });
  };

  currencyConverter = amount => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NGN'
    });
    return formatter.format(amount);
  };

  formatDate = date => {
    if (date !== null) {
      const user_date = new Date(date);
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];

      return `${user_date.getDate()}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()}`;
    } else {
      return '--';
    }
  };

  getPatientRecord = async () => {
    await axios
      .get(`${serverLink}patient/${hospitalCode}/${patientSerial}`, patient_login_token)
      .then(result => {
        this.setState({
          isLoading: false,
          walletAmount: this.currencyConverter(result.data.walletAmount),
          patientRecord: result.data,
          dependantList: result.data.dependantList,
          patientSerial: result.data.patientSerial,
          patientFullName: `${result.data.firstName} ${result.data.middleName} ${result.data.surname}`,
          patientEmail: result.data.emailAddress,
          patientPhoneNo: result.data.phoneNumber,
          paymentAmount: '',

          onSuccess: false,
          onError: false,
          onMessage: ''
        });
      })
      .catch(error => {
        console.log('Dashboard data error', error);
      });
  };

  handleDateChange = date => {
    if (this.state.formData.doctorID === '') {
      this.setState({
        onError: true,
        onMessage: 'Select Doctor First'
      });
      return false;
    } else {
      if (date.target.value !== null) {
        const appointmentDate = new Date(date.target.value);
        this.state.formData.appointmentDate = date.target.value;
        this.state.formData.appointmentTime = '';

        let days = [
          'sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday'
        ];
        const appointment_day = days[appointmentDate.getDay()];

        axios
          .get(
            `${serverLink}doctor_schedule/${hospitalCode}/${this.state.formData.doctorID}`, patient_login_token
          )
          .then(result => {
            let schedules = result.data;

            if (schedules === null) {
              this.setState({
                onError: true,
                onMessage: 'No Schedule Found for the selected doctor'
              });
            } else {
              this.setState({
                onError: false
              });

              let slots = schedules.scheduleData[0][appointment_day];

              if (typeof slots !== 'undefined') {
                this.setState({
                  onError: false,
                  doctorSlots: slots.slots
                });
                this.state.formData.appointmentDay = appointment_day;
              } else {
                this.setState({
                  onError: true,
                  onMessage:
                    "The Selected Doctor doesn't have a schedule on " +
                    appointment_day.toUpperCase()
                });
              }
            }

            this.setState({
              isLoading: false
            });
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
          });
      } else {
        this.setState({
          formData: {
            ...this.state.formData,
            appointmentDate: ''
          },
          doctorSlots: ''
        });
      }
    }
  };

  handleChange = event => {
    this.setState({
      onError: false,
      onSuccess: false
    });
    if (event.target.name === 'select-patient') {
      const patient = JSON.parse(event.target.value);
      this.setState({
        formData: {
          ...this.state.formData,
          patientSerial: patient.patientSerial,
          patientName: `${patient.firstName} ${patient.middleName} ${patient.surname}`
        }
      });
    } else if (event.target.name === 'select-department') {
      this.setState({
        formData: {
          ...this.state.formData,
          doctorID: '',
          doctorName: ''
        },
        doctorSlots: ''
      });
      const department = JSON.parse(event.target.value);
      if (department.specialisationName === 'N/A') {
        this.setState({
          onError: true,
          onMessage: 'You cannot select N/A for a booking appointment',
          appointmentDepartment: ''
        });
        return false;
      } else {
        this.setState({
          formData: {
            ...this.state.formData,
            appointmentDepartment: department.specialisationName
          }
        });
        axios
          .get(
            `${serverLink}doctor/department_doctors/${hospitalCode}/${department.specialisationName}`, patient_login_token
          )
          .then(doctors => {
            if (doctors.data.length > 0) {
              this.setState({
                doctorList: doctors.data
              });
            } else {
              this.setState({
                onError: true,
                onMessage: 'No Doctor Found in the Selected Department'
              });
              return false;
            }
          })
          .catch(err => {
            console.log('ERROR: ', err);
          });
      }
    } else if (event.target.name === 'select-doctor') {
      //onShowAppointmentDate
      if (event.target.value !== '') {
        const doctor = JSON.parse(event.target.value);
        this.setState({
          formData: {
            ...this.state.formData,
            doctorID: doctor.userID,
            doctorName: `${doctor.firstName} ${doctor.middleName} ${doctor.surname}`
          },
          onShowAppointmentDate: false
        });
      } else {
        this.setState({
          onShowAppointmentDate: true
        });
      }
    } else if (event.target.name === 'appointment-slot') {
      if (event.target.value !== '') {
        const appointmentTime = event.target.value;
        this.setState({
          formData: {
            ...this.state.formData,
            appointmentTime: appointmentTime
          }
        });
      } else {
        this.setState({
          ...this.state.formData,
          appointmentTime: '',
          onShowAppointmentDate: true
        });
      }
    }
  };

  async bookAppointment(sendData) {
    this.setState({
      onSubmitting: true
    });

    if (sendData.patientSerial === '') {
      this.setState({
        onSubmitting: false,
        onError: true,
        onMessage: 'Please Select the Patient'
      });
      return false;
    }

    if (sendData.serviceID === '') {
      this.setState({
        onSubmitting: false,
        onError: true,
        onMessage: 'Please Select the Consultation'
      });
      return false;
    }


    if (sendData.appointmentDepartment === '') {
      this.setState({
        onSubmitting: false,
        onError: true,
        onMessage: 'Please Select the Appointment Department'
      });
      return false;
    }

    if (sendData.doctorID === '') {
      this.setState({
        onSubmitting: false,
        onError: true,
        onMessage: 'Please Select the Appointment Doctor'
      });
      return false;
    }

    if (sendData.appointmentDate === '') {
      this.setState({
        onSubmitting: false,
        onError: true,
        onMessage: 'Please Select the Appointment Date'
      });
      return false;
    }

    if (sendData.appointmentTime === '') {
      this.setState({
        onSubmitting: false,
        onError: true,
        onMessage: 'Please Select the Appointment Time'
      });
      return false;
    }

    if (this.state.patientRecord.walletAmount < sendData.appointmentFee) {
      this.setState({
        onSubmitting: false,
        onError: true,
        onMessage:
          'Insufficient wallet balance. Please recharge your wallet before booking an appointment'
      });
      return false;
    }
    await this.handlePayment(sendData);
  }

  handlePayment = sendAppointmentData => {
    const item_amount = parseInt(sendAppointmentData.appointmentFee);
    const paymentMethod = 'Wallet';

    const sendData = {
      patientSerial: sendAppointmentData.patientSerial,
      patientName: sendAppointmentData.patientName,
      paymentFor: 'Appointment',
      itemName: sendAppointmentData.appointmentDepartment,
      paymentAmount: item_amount,
      paymentStatus: 'paid',
      paymentType: 'Debit',
      hospitalCode: hospitalCode,
      amountDue: item_amount,
      paymentMethod: paymentMethod,
      submittedBy: sendAppointmentData.submittedBy,
      submittedByName: sendAppointmentData.patientName,
      appointmentID: '',
      deductionFromID: 'Wallet',
      deductionFromName: 'Wallet'
    };

    axios
      .post(`${serverLink}payment/process_payment`, sendData, patient_login_token)
      .then(result => {
        if (result.data.message === 'success') {
          axios
            .post(`${serverLink}appointment/add`, sendAppointmentData, patient_login_token)
            .then(result => {
              this.setState({
                onSubmitting: false
              });
              const response = result.data.message;
              if (response === 'success') {
                this.setState({
                  onSuccess: true
                });
                setTimeout(function() {
                  window.location.href = '/Dashboard';
                }, 3000);
              } else if (response === 'failed') {
                this.setState({
                  onError: true,
                  onMessage:
                    'Something went wrong saving the appointment. Please try again!'
                });
              } else if (response === 'error') {
                this.setState({
                  onError: true,
                  onMessage:
                    'An error occur adding the appointment. Please try again!'
                });
              } else {
                this.setState({
                  onError: true,
                  onMessage: response
                });
              }
            })
            .catch(err => {
              this.setState({
                onError: true,
                onMessage: err
              });
            });
        } else {
          this.setState({
            onError: true,
            onMessage: 'Something went wrong booking your appointment!!!'
          });
          return false;
        }
      })
      .catch(error => {
        console.log('Appointment failed', error);
        this.setState({
          onError: true,
          onMessage:
            'Appointment failed. Connection to the server was lost! Please try again.'
        });
        return false;
      });
  };

  render() {
    return (
      <Fragment>
        <PageTitle titleHeading="Add Appointment" titleDescription="" />

        {this.state.isLoading === true ? (
          <LinearProgress value={25} className="mb-3" />
        ) : (
          <>
            <WrapperSeamless sectionHeading="Book Appointment">
              <Fragment>
                <Grid container spacing={4}>
                  <Grid item xs={6} md={6}>
                    <Card className="card-box bg-plum-plate text-light mb-4">
                      <CardContent className="p-3">
                        <div className="d-flex align-items-start">
                          <div className="font-weight-bold">
                            <small className="text-white-50 d-block mb-1 text-uppercase">
                              Current Wallet Balance
                            </small>
                            <span className="font-size-xxl mt-1">
                              {this.state.walletAmount}
                            </span>
                          </div>
                          <div className="ml-auto">
                            <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                              <FontAwesomeIcon
                                icon={['fa', 'money-bill']}
                                className="font-size-xl"
                              />
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Card className="card-box bg-plum-plate text-light mb-4">
                      <CardContent className="p-3">
                        <div className="d-flex align-items-start">
                          <div className="font-weight-bold">
                            <small className="text-white-50 d-block mb-1 text-uppercase">
                              Appointment Fee:{' '}
                              {this.state.formData.appointmentDepartment}
                            </small>
                            <span className="font-size-xxl mt-1">
                              {this.currencyConverter(
                                this.state.formData.consultationAmount
                              )}
                            </span>
                          </div>
                          <div className="ml-auto">
                            <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                              <FontAwesomeIcon
                                icon={['fa', 'money-bill']}
                                className="font-size-xl"
                              />
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Card className="mb-4">
                      <CardContent className="p-3">
                        <h5 className="card-title font-weight-bold font-size-lg">
                          Book Appointment
                        </h5>
                        <hr />

                        <div className="p-3">
                          <TextField
                            fullWidth
                            className="m-2"
                            id="select-patient"
                            name="select-patient"
                            select
                            defaultValue={JSON.stringify(
                              this.state.patientRecord
                            )}
                            label="Select Patient"
                            onChange={this.handleChange}
                            helperText="You can pay to your wallet or select your dependant and pay directly to their wallet">
                            <MenuItem
                              key={this.state.patientRecord._id}
                              value={JSON.stringify(this.state.patientRecord)}>
                              {this.state.patientRecord.firstName}{' '}
                              {this.state.patientRecord.middleName}{' '}
                              {this.state.patientRecord.surname}
                            </MenuItem>

                            {this.state.dependantList.length > 0
                              ? this.state.dependantList.map(option => (
                                  <MenuItem
                                    key={option._id}
                                    value={JSON.stringify(option)}>
                                    {option.firstName} {option.middleName}{' '}
                                    {option.surname}
                                  </MenuItem>
                                ))
                              : ''}
                          </TextField>

                          <TextField
                            fullWidth
                            className="m-2"
                            id="serviceType"
                            name="serviceType"
                            select
                            defaultValue=""
                            label="Consultation Type"
                            onChange={this.onServiceChange}
                            helperText="Select Consultation Type">
                            {this.state.serviceList.length > 0 &&
                              this.state.serviceList.map(option => (
                                <MenuItem key={option._id} value={option._id}>
                                  {option.serviceName}
                                </MenuItem>
                              ))}
                          </TextField>



                          <TextField
                            fullWidth
                            className="m-2"
                            id="serviceType"
                            name="serviceType"
                            select
                            defaultValue=""
                            label="Consultation"
                            onChange={this.onServiceItemChange}
                            helperText="Select Consultation">
                            {this.state.serviceDataSelect.length > 0
                              ? this.state.serviceDataSelect.map(option => (
                                  <MenuItem
                                    key={option._id}
                                    value={JSON.stringify(option)}>
                                    {option.serviceItemName}
                                  </MenuItem>
                                ))
                              : ''}
                          </TextField>

                          <TextField
                            fullWidth
                            className="m-2"
                            id="select-department"
                            name="select-department"
                            select
                            defaultValue=""
                            label="Select Department"
                            onChange={this.handleChange}
                            helperText="Select Appointment Department">
                            {this.state.departmentList.length > 0
                              ? this.state.departmentList.map(option => (
                                  <MenuItem
                                    key={option._id}
                                    value={JSON.stringify(option)}>
                                    {option.specialisationName}
                                  </MenuItem>
                                ))
                              : ''}
                          </TextField>

                          <TextField
                            fullWidth
                            className="m-2"
                            id="select-doctor"
                            name="select-doctor"
                            select
                            defaultValue=""
                            label="Select Doctor"
                            onChange={this.handleChange}
                            helperText="Select Department Before Seeing the Doctor List">
                            {this.state.appointmentDepartment !== ''
                              ? this.state.doctorList.map(option => (
                                  <MenuItem
                                    key={option._id}
                                    value={JSON.stringify(option)}>
                                    {`${option.firstName} ${option.middleName} ${option.surname}`}
                                  </MenuItem>
                                ))
                              : ''}
                          </TextField>

                          <TextField
                            fullWidth
                            type="date"
                            label="Appointment Date"
                            id="appointment-date"
                            name="appointment-date"
                            disabled={this.state.onShowAppointmentDate}
                            onChange={this.handleDateChange}
                            helperText="Select Appointment Date"
                            className="m-2"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  Appointment Date
                                </InputAdornment>
                              )
                            }}
                          />

                          {this.state.doctorSlots !== '' ? (
                            <TextField
                              fullWidth
                              className="m-2"
                              id="appointment-slot"
                              name="appointment-slot"
                              select
                              defaultValue=""
                              label="Appointment Slots"
                              onChange={this.handleChange}
                              helperText="Doctor's appointment slots.">
                              {this.state.doctorSlots.map((slot, index) => {
                                const appointment_date = this.state.formData
                                  .appointmentDate;
                                const today = new Date();
                                const currentDate =
                                  today.getFullYear() +
                                  '-' +
                                  (today.getMonth() + 1) +
                                  '-' +
                                  today.getDate();
                                const currentTime =
                                  today.getHours() + ':' + today.getMinutes();

                                let slotArray = slot.slot.split(' - ');
                                if (
                                  Date.parse(
                                    `${currentDate} ${currentTime}:00`
                                  ) <
                                  Date.parse(
                                    `${appointment_date} ${slotArray[0]}:00`
                                  )
                                ) {
                                  if (slot.status === 'available') {
                                    return (
                                      <MenuItem
                                        key={slot._id}
                                        value={slot.slot}>
                                        {slot.slot}
                                      </MenuItem>
                                    );
                                  }
                                }
                              })}
                            </TextField>
                          ) : (
                            ''
                          )}

                          <Button
                            onClick={() =>
                              this.bookAppointment(this.state.formData)
                            }
                            size="small"
                            variant="contained"
                            className="mt-3 mb-2 button"
                            color="primary">
                            <FontAwesomeIcon icon={['fas', 'plus']} />
                            Book Appointment
                          </Button>

                          {this.state.onSuccess === true ? (
                            <MuiAlert className="mb-4" severity="success">
                              <div className="d-flex align-items-center align-content-center">
                                <span>
                                  <strong className="d-block">
                                    Appointment booked successfully!
                                  </strong>{' '}
                                  {this.state.onMessage}
                                </span>
                              </div>
                            </MuiAlert>
                          ) : (
                            ''
                          )}

                          {this.state.onError === true ? (
                            <MuiAlert className="mb-4" severity="error">
                              <div className="d-flex align-items-center align-content-center">
                                <span>
                                  <strong className="d-block">Error!</strong>{' '}
                                  {this.state.onMessage}
                                </span>
                              </div>
                            </MuiAlert>
                          ) : (
                            ''
                          )}

                          {this.state.onSubmitting === true ? (
                            <MuiAlert className="mb-4" severity="info">
                              <div className="d-flex align-items-center align-content-center">
                                <span>
                                  <strong className="d-block">
                                    Processing! Please wait
                                  </strong>{' '}
                                  {this.state.onMessage}
                                </span>
                              </div>
                            </MuiAlert>
                          ) : (
                            ''
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Fragment>
            </WrapperSeamless>
          </>
        )}
      </Fragment>
    );
  }
}

export default AddAppointment;
