import React, { Fragment, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, CardContent } from '@material-ui/core';
class DashboardSection1 extends Component {
  render() {
    return (
      <Fragment>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <Card className="card-box bg-midnight-bloom text-light mb-4">
              <CardContent className="p-3">
                <div className="d-flex align-items-start">
                  <div className="font-weight-bold">
                    <small className="text-white-50 d-block mb-1 text-uppercase">
                      Appointments
                    </small>
                    <span className="font-size-xxl mt-1">
                      {this.props.appointmentCount}
                    </span>
                  </div>
                  <div className="ml-auto">
                    <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon
                        icon={['far', 'calendar']}
                        className="font-size-xl"
                      />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className="card-box bg-plum-plate text-light mb-4">
              <CardContent className="p-3">
                <div className="d-flex align-items-start">
                  <div className="font-weight-bold">
                    <small className="text-white-50 d-block mb-1 text-uppercase">
                      Current Wallet
                    </small>
                    <span className="font-size-xxl mt-1">
                      {this.props.currentWallet}
                    </span>
                  </div>
                  <div className="ml-auto">
                    <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon
                        icon={['fa', 'money-bill']}
                        className="font-size-xl"
                      />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default DashboardSection1;
